<template>
    <div class="socialcall" v-if="$store.state.is_social_shown">
        <button class="close socialcall__close" @click.self="$store.commit('closeSocial')"></button>

        <div class="socialcall__pointer">
          <span class="icon-arrow"></span>
          <span class="icon-arrow"></span>
        </div>

        <div class="socialcall__alert" v-if="showAlert">{{ 'social_alert' | localize }}</div>
        <TextBlock :id="17" />
    </div>
</template>

<script>
import TextBlock from '@/components/Text.vue'

export default {
  name: 'Social_call',
  components: { TextBlock },
  props: {
    showAlert: { type: Boolean, default: false }
  }
}
</script>
