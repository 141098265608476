<template>
  <div class="form">
    <div class="background background_blue background_contacts">
      <div class="background__image background__image_contacts"></div>
    </div>

    <div class="wrapper">
      <div class="content">
        <div class="content__container contacts">
          <div class="contacts__title">{{ 'contacts' | localize }}</div>
          <TextBlock class="contacts__text" :id="10" />
        </div>
      </div>
    </div>
    <Social class="social_contacts" />
    <SocialCall />
  </div>
</template>

<script>
import Social from '@/components/base/Social.vue'
import SocialCall from '@/components/base/SocialCall.vue'
import TextBlock from '@/components/Text.vue'
import { commonMixin } from '@/mixins/commonMixin.js'

export default {
  name: 'Contacts',
  components: { Social, SocialCall, TextBlock },
  mixins: [commonMixin]
}
</script>

<style lang="scss" scoped>
  @import "~@/assets/styles/default/vars.scss";

  .contacts {
    padding-top: 20vh;
    padding-left: 40vw;

    &__title {
      font-size: 30px;
      line-height: 38px;
      margin-bottom: 50px;
      color: $green;
    }

    &__text, &__text .text__body {
      font-size: 16px;
      line-height: 20px;

      em {
        font-style: normal;
        color: $green;
      }
    }
  }

  .background {
    &_blue {
      &:after {
        z-index: 2;
        opacity: 1;
        background: linear-gradient(89.78deg, rgba(5, 2, 141, 0) 7.15%, #05028D 73.18%);
      }
    }
    &__image {
      opacity: 1;
      z-index: 1;
      position: relative;

      &.background__image_contacts {
        background-image: url("~@/assets/images/common/contacts.png");
      }
    }
  }

  #app.light-theme {
    .background_blue {
      &:after {background: linear-gradient(89.78deg, rgba(255, 255, 255, 0) 7.15%, #FFFFFF 73.18%);
      }
    }
    .contacts {
      &__title {
        color: $green_dark;
      }

      &__text, &__text .text__body {
        em {
          color: $green_dark;
        }
      }
    }
  }

  #app.locale_heb {
    .contacts {
      padding-left: 0;
      padding-right: 40vw;
    }
    .background_blue {
      &:after {
        transform: rotate(-180deg);
      }
    }
  }

  @media screen and (max-width: 1024px) {
    .background_contacts {
      transform: scale(-1, 1);
      display: block;
      position: absolute;
      width: 100vw;
      height: 56vw;
      top: 90px;

      &.background_blue {
        &:before,
        &:after {
          display: block;
          position: absolute;
          width: 100vw;
          height: 56vw;
          top: 0;
        }
      }
    }
    .contacts {
      padding: 0;

      &__title {
        font-size: 20px;
        line-height: 30px;
        margin-bottom: 50vw;
      }
    }

    #app.locale_heb {
      .contacts {
        padding: 0;
      }
    }
  }
</style>